import React from "react"
import { useForm } from "@formspree/react"

import { BUTTON_CLASSES } from "./Button";


const ContactForm = () => {
  const [state, handleSubmit] = useForm("xayawyjk");

  if (state.succeeded) {
    return <p className="text-center text-3xl">Grazie per avermi contattato!</p>;
  }

  const defaultInputClassNames = "w-full pt-1 pl-1 mb-4 border-b-2 border-yellow";

  return <form className="w-full flex flex-col" onSubmit={handleSubmit}>
    <label>
      <b>Indirizzo email<span style={{ color: "red" }}>*</span></b>
      <input
        required
        className={defaultInputClassNames}
        type="email"
        name="email"
        placeholder="Il tuo indirizzo email"
      />
    </label>

    <label>
      <b>Nome<span style={{ color: "red" }}>*</span></b>
      <input
        required
        className={defaultInputClassNames}
        type="text"
        name="nome"
        placeholder="Il tuo nome"
      />
    </label>


    <label>
      <b>Numero di telefono</b>
      <input
        className={defaultInputClassNames}
        type="text"
        name="numero_di_telefono"
        placeholder="Il tuo numero di telefono"
      />
    </label>

    <label>
      <b>Messaggio<span style={{ color: "red" }}>*</span></b>
      <textarea
        required
        className={defaultInputClassNames}
        name="messaggio"
        placeholder="Messaggio"
      />
    </label>

    <b>Privacy<span style={{ color: "red" }}>*</span></b>
    <label className="mb-4">
      <input
        required
        className="mr-2"
        type="checkbox"
        name="accettazione_privacy"
      />
      Con l’invio del presente modulo acconsento al trattamento dei dati personali per le sole finalità indicate, secondo la normativa vigente sulla Privacy – UE (GDPR) 2016/679. Leggi la Privacy Policy nella sezione "Privacy e Cookie Policy".
    </label>

    <input type="text" name="_gotcha" className="hidden" />

    <div className="text-center">
      <button
        type="submit"
        className={BUTTON_CLASSES}
        disabled={state.submitting}
      >
        Invia
      </button>
    </div>
  </form>
};

export default ContactForm;
