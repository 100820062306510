import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Hero from "../../components/Hero"
import ContactForm from "../../components/ContactForm"

const ContattiPage = () => {
  return <Layout>
    <Seo title="Contatti" />
    <Hero title="Contatti" />
    {/* Main section with infos */}
    <section className="grid grid-cols-6 py-6">
      <article className="col-span-6 md:col-start-2 md:col-span-2 px-5 md:p-0 text-center">
        <StaticImage
          src="../../assets/van-gogh.jpg"
          alt="Vincent van Gogh - Portrait of Joseph Roulin"
        />
      </article>
      <article className="col-span-6 md:col-span-2 pt-5 px-5 md:pr-0 text-xl md:text-2xl break-words">
        <p>
          <b>Email</b>:<br />
          <a className="no-underline" href="mailto:francesco.latini.psy@gmail.com">francesco.latini.psy@gmail.com</a>
        </p>
        <br />
        <p>
          <b>Telefono</b>:<br />
          <a className="no-underline" href="tel:+393922417807">+39 392 24 17 807</a>
        </p>
        <br />
        <p>
          <b>Indirizzo studio</b>:<br />
          Via Lario 25, Milano, 20159
        </p>
        <br />
        <p>Comodamente collegato con:</p>
        <ul className="list-disc list-outside pl-9">
          <li>M3/M5 Zara  (3 minuti)</li>
          <li>Tram 2/4 (7 minuti)</li>
          <li>Autobus 91/92 (2 minuti)</li>
          <li>Autobus 60 (1 minuto)</li>
        </ul>
      </article>
    </section>
    {/* Map */}
    <section>
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.807553692144!2d9.188771315928957!3d45.49381997910142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c12afb6161cb%3A0x9f069919ad51c3c1!2sVia%20Lario%2C%2025%2C%2020159%20Milano%20MI!5e0!3m2!1sit!2sit!4v1616158005805!5m2!1sit!2sit"
        width="100%"
        height="375"
        allowFullScreen={false}
        loading="lazy"
      ></iframe>
    </section>
    {/* Contact form */}
    <section className="grid grid-cols-6 py-6">
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0 text-lg">
        <h2>Come contattarmi:</h2>
        <p className="mb-4">Per informazioni o prenotare un appuntamento è possibile contattarmi al telefono, via email oppure compilando il modulo sottostante.</p>
        <ContactForm />
      </article>
    </section>
  </Layout>;
};

export default ContattiPage;
